import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MetaTags } from "react-meta-tags";
import { withTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import {
  Table, Thead, Tbody, Tr, Th
} from "react-super-responsive-table";

import { fetchAgreements, fetchReferrals } from "store/actions";

import CardWrapper from "components/Common/CardWrapper";
import RecursiveTableRows from "./RecursiveTableRows";
import Select from "react-select";
import PageHeader2 from "components/Forex/Common/pageHeader2";
import IbNotApproved from "components/Common/IbNotApproved";
import Loader from "components/Common/Loader";
import {
  Button,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";
import CustomSelect from "components/Common/CustomSelect";

const Referral = ({ t }) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const { agreements, loading } = useSelector((state) => state.forex.ib.agreements);
  const referrals = useSelector((state) => state.forex.ib.agreements.referrals);
  const { subPortal } = useSelector(state => state.Layout);
  const partnershipAgreement = useSelector((state) => (state.Profile.clientData.stages?.ib?.partnershipAgreement));

  const [isIbPortal] = useState(subPortal === "IB");
  const [selectedAgreement, setSelectedAgreement] = useState(undefined);

  useEffect(() => {
    dispatch(fetchReferrals({
      type: "live",
    }));
    dispatch(fetchAgreements());
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = referrals.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="page-content">
      <MetaTags>
        <title>{t("Referrals")}</title>
      </MetaTags>
      <Container className="mt-5">
        {isIbPortal && !partnershipAgreement && <IbNotApproved />}
        <PageHeader2 title="Referrals" />
        <div className="container">
          <div className="row">
            <div className="col-4">
              <label>{t("Select Agreement")}</label>
              <CustomSelect
                className="w-full"
                name="agreement"
                onChange={(e) => { setSelectedAgreement(e.value) }}
                value={selectedAgreement ? selectedAgreement?.value : ""}
                options={agreements?.map((agreement) => {
                  return ({
                    label: agreement.title,
                    value: agreement._id,
                  });
                })}
              />
            </div>
            <div className="row col-md-auto align-content-end">
              <Button className="color-bg-btn border-0" onClick={() => { setSelectedAgreement(undefined) }}>
                {t("Clear")}
              </Button>
            </div>
          </div>
        </div>

        <CardWrapper className="p-3 mt-4 glass-card shadow">
          {loading ? <Loader /> : referrals.length > 0 &&
            <Table className="table table-hover text-center">
              <Thead>
                <Tr>
                  <Th className="py-2">{t("Name")}</Th>
                  <Th className="py-2">{t("Client Type")}</Th>
                  <Th className="py-2">{t("Accounts")}</Th>
                  <Th className="py-2">{t("Parent Name")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                <RecursiveTableRows data={currentItems[0]} filter={selectedAgreement} />
              </Tbody>
            </Table>}
        </CardWrapper>
        <div className="d-flex justify-content-center mt-3">
          <Pagination>
            <PaginationItem className="mt-3">
              <PaginationLink onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}>{"<"}</PaginationLink>
            </PaginationItem>
            <div className="pagination mt-3">
              {Array.from({ length: Math.ceil(referrals.length / itemsPerPage) }).map((_, i) => (
                <PaginationItem
                  key={i}
                  active={i + 1 === currentPage ? true : undefined}
                >
                  <PaginationLink
                    onClick={() => paginate(i + 1)}
                    key={i}
                  >
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
            </div>
            <PaginationItem className="mt-3">
              <PaginationLink
                onClick={() => paginate(currentPage + 1)}
                disabled={indexOfLastItem >= referrals.length}
              >
                {">"}
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </div>
      </Container>
    </div>
  );
};

export default withTranslation()(Referral);
