
import React, { useEffect } from "react";
import "./webtrader.scss";

const WebFeed = (props) => {

  return (
    <>
      <div className="container-iframe">
        <iframe className="responsive-iframe" src="https://component.autochartist.com/news/stock-news?broker_id=825&account_type=LIVE&user=Wealth-World-Markets&expire=1767650400&token=8a9baeb7e1b7512f77a24c203e49326e&locale=en&css=https://broker-resources.autochartist.com/css/components/825-news-feeds-app.css"></iframe>
      </div>
    </>
  );
};

export default WebFeed;