// <div id="webterminal" style="width:100%;height:600px;"></div>
// <script type="text/javascript" src="https://trade.mql5.com/trade/widget.js"></script>
// <script type="text/javascript">
//     new MetaTraderWebTerminal( "webterminal", {
//         version: 5,
//         server: "MetaQuotes-Demo",
//         demoAllServers: true,
//         startMode: "create_demo",
//         lang: "en",
//         colorScheme: "black_on_white"
//     } );
// </script>


import React, { useEffect } from "react";
import "./webtrader.scss";
const WebCalendar = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    console.log("11111111111111");
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const getWindowDimensions = () => {

    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  };
  const [height, setHeight] = React.useState(600);
  useEffect(() => {
    const { height } = getWindowDimensions();
    setHeight(height - (height * 0.1));
  }, []);

  return (
    <>
      <div className="container-iframe">
        <iframe className="responsive-iframe" src="https://eia.autochartist.com/calendar/?broker_id=825&showall=true&nextdays=3&token=8a9baeb7e1b7512f77a24c203e49326e&expire=1767650400&user=Wealth-World-Markets&locale=en&css=https://broker-resources.autochartist.com/css/components/825-calendar-app.css"></iframe>
      </div>

    </>
  );
};


export default WebCalendar;