import logo from "assets/images/brands/logo512.png";


export const clientName = "WWFM";
export const developedBy = "ITGeeks";
export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL || "http://localhost:3001/static/";
export const cpUrl = process.env.REACT_APP_CP_URL || "http://localhost:4002";
export const apiUrl = process.env.REACT_APP_API_CP_DOMAIN || "http://localhost:3001";
export const companyName =  "WWFM";
export const companyWebsite = "https://wealthworldmarkets.com/";
export const privacyPolicyLink = "";
export const clientAgreementLink = "/WWMClientAgreement.pdf";
export const sidebarLogo = logo;
export const smLogo = logo;
export const mainLogo = logo;
export const lightLogo = logo;
