import TableLoader from "components/Common/TableLoader";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Tbody, Th, Thead, Tr 
} from "react-super-responsive-table";
import {
  Pagination,
  PaginationItem,
  PaginationLink,
  Table
} from "reactstrap";

function DetailsTable(props) {
  const { t, isLoading, columns, rows, onRowClick } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = rows.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  
  return (
    <>
      <Table borderless responsive className="text-center mb-0">
        <Thead className="text-center table-light">
          <Tr>
            {columns.map((column, index) => (
              <Th data-priority={index} key={index} className="color-primary">
                {column.text}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody className="text-center">
          {isLoading && <TableLoader colSpan={12} />}
          {!isLoading && (rows.length > 0 ?  
            currentItems.map((row, rowIndex) => (
              <tr key={rowIndex} onClick={() => { onRowClick(row) }} style={{ cursor: "pointer" }}>
                {columns.map((column, index) => (
                  <td key={`${rowIndex}-${index}`}>
                    {column.formatter
                      ? column.formatter(row, rowIndex)
                      : row[column.dataField]}
                  </td>
                ))}
              </tr>
            )) : 
            <tr>
              <td colSpan={columns.length} className="text-center">
                <p className="m-3">{t("No Clients Available")}</p>
              </td>
            </tr>
          )}
        </Tbody>
      </Table>
      <div className="d-flex justify-content-center mt-3">
        <Pagination>
          <PaginationItem className="mt-3">
            <PaginationLink onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}>{"<"}</PaginationLink>
          </PaginationItem>
          <div className="pagination mt-3">
            {Array.from({ length: Math.ceil(rows.length / itemsPerPage) }).map((_, i) => (
              <PaginationItem
                key={i}
                active={i + 1 === currentPage ? true : undefined}
              >
                <PaginationLink
                  onClick={() => paginate(i + 1)}
                  key={i}
                >
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            ))}
          </div>
          <PaginationItem className="mt-3">
            <PaginationLink
              onClick={() => paginate(currentPage + 1)}
              disabled={indexOfLastItem >= rows.length}
            >
              {">"}
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </div>
    </>
  );
}

export default withTranslation()(DetailsTable);