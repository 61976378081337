import React, { useEffect } from "react";

import "./webtrader.scss";
export const WebTradingOpportunities = (props) => {

  return (
    <>
      <div className="container-iframe">
        <iframe className="responsive-iframe" src="https://component.autochartist.com/to/?broker_id=825&token=8a9baeb7e1b7512f77a24c203e49326e&expire=1767650400&user=Wealth-World-Markets&locale=en_GB&layout=horizontal&account_type=LIVE&trade_now=y&css=https://broker-resources.autochartist.com/css/components/825-to-app.css"></iframe>
      </div>
    </>
  );
};